import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URI, MOTORGROUPID } from "../shared/Constants";

export default function OurDealersController() {
  const [loading, setLoading] = useState(true);
  const [dealers, setDealers] = useState([]);

  useEffect(() => {
    let cancel;
    setLoading(true);
    setDealers([]);

    let url = `${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`;

    axios({
      method: "GET",
      url: url,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        setDealers(response?.data);
        setLoading(false);

        //TODO: Missing Fields
        //dealer.businessHours
        //dealer.services
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });
    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MOTORGROUPID]);

  return { loading, dealers };
}
